<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Články - Typy - Kontakt - Upravit <span class="text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item
          label="Název"
          :validate-status="error('ale_name') ? 'error' : ''"
          :help="error('ale_name') || ''"
        >
          <a-input placeholder="Název" v-decorator="['ale_name', {rules: [{max: 60, message: 'Název nemůže být delší než 100 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Nadpis tučně"
          :validate-status="error('ale_title') ? 'error' : ''"
          :help="error('ale_title') || ''"
        >
          <a-input placeholder="Nadpis tučně" v-decorator="['ale_title', {rules: [{max: 60, message: 'Nadpis tučně nemůže být delší než 60 znaků!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Nadpis kurzivou"
          :validate-status="error('ale_title_thin') ? 'error' : ''"
          :help="error('ale_title_thin') || ''"
        >
          <a-input placeholder="Nadpis kurzivou" v-decorator="['ale_title_thin', {rules: [{max: 60, message: 'Nadpis kurzivou nemůže být delší než 60 znaků!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Popisek"
          :validate-status="error('ale_description') ? 'error' : ''"
          :help="error('ale_description') || ''"
        >
          <a-input
            type="textarea"
            :autoSize="{ minRows: 4, maxRows: 10 }"
            placeholder="Popisek"
            v-decorator="['ale_description']"
          />
        </a-form-item>

        <a-form-item label="Zveřejnit?">
          <a-checkbox v-decorator="['ale_is_active', { valuePropName: 'checked', initialValue: true }]"/>
        </a-form-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Upravit</a-button>
        </div>
      </a-form>
    </div>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'

export default {
  components: {
    ActionTools, LanguageTab,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  data() {
    return {
      hasErrors,
      loading: false,
      id: this.$route.params.id,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/article/type/ctt',
          title: 'Seznam',
        },
      ],
      item: {
        languages: [],
      },
      loaded: [],
    }
  },
  computed: {
    currentDetailLang: function() {
      return this.$store.getters['article/currentLanguageDetail']
    },
    detail: function() {
      return this.$store.getters['article/getDetail']
    },
    language: function () {
      return this.$store.getters['language/active']
    },
  },
  methods: {
    initDetail() {
      this.loading = true
      this.$store.dispatch('article/getOne', { id: this.id, type: 'CTT' })
        .then(() => {
          this.item = Object.assign({}, this.detail)
          this.form.setFieldsValue({
            ale_title_thin: this.currentDetailLang.ale_title_thin,
            ale_description: this.currentDetailLang.ale_description,
            ale_title: this.currentDetailLang.ale_title,
            ale_name: this.currentDetailLang.ale_name,
            ale_is_active: this.currentDetailLang.ale_is_active,
          })
          this.form.validateFields()
        }).catch(() => {})
        .finally(() => {
          this.loaded.push(this.language)
          this.loading = false
        })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('article/put', { id: this.item.ate_id, item: this.getData() })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      return {
        languages: this.item.languages.filter(x => x.ale_name !== undefined && x.ale_name.trim().length > 0).map(x => {
          return {
            lge_id: x.lge_id,
            ale_title_thin: x.ale_title_thin !== undefined ? x.ale_title_thin : null,
            ale_content: null,
            ale_description: x.ale_description !== undefined ? x.ale_description : null,
            ale_title: x.ale_title !== undefined ? x.ale_title : null,
            ale_name: x.ale_name !== undefined ? x.ale_name : null,
            ale_is_active: x.ale_is_active,
          }
        }),
      }
    },
    handleChange(oldLanguage) {
      const foundObj = { article: false }
      const newObj = {
        article: {
          lge_id: oldLanguage,
          ale_title_thin: this.form.getFieldValue('ale_title_thin'),
          ale_description: this.form.getFieldValue('ale_description'),
          ale_title: this.form.getFieldValue('ale_title'),
          ale_name: this.form.getFieldValue('ale_name'),
          ale_is_active: this.form.getFieldValue('ale_is_active'),
        },
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.article
          foundObj.article = true
          return true
        }
      })
      if (!foundObj.article) {
        this.item.languages.push(newObj.article)
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    refreshComponent(id) {
      this.id = id
      this.item = {
        languages: [],
      }
      this.loaded = []
    },
  },
  created() {
    if (this.language !== null && !this.loaded.includes(this.language)) {
      this.initDetail()
    }
  },
  watch: {
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      if (this.language !== null) {
        this.initDetail()
      }
    },
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        if (!this.loaded.includes(this.language)) {
          this.initDetail()
        } else {
          const foundObj = { article: this.item.languages.find(x => x.lge_id === newValue) }
          let newObj
          if (foundObj.article === undefined) {
            newObj = {
              ale_title_thin: undefined,
              ale_description: undefined,
              ale_title: undefined,
              ale_name: undefined,
              ale_is_active: true,
            }
          } else {
            newObj = {
              ale_title_thin: foundObj.article.ale_title_thin,
              ale_description: foundObj.article.ale_description,
              ale_title: foundObj.article.ale_title,
              ale_name: foundObj.article.ale_name,
              ale_is_active: foundObj.article.ale_is_active,
            }
          }
          this.form.setFieldsValue(newObj)
          if (!this.loaded.includes(this.language)) {
            this.initDetail()
          }
        }
        this.form.validateFields()
      }
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
